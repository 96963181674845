
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
html {
  scroll-behavior: smooth;
}

header{
  position: fixed;
  z-index: 25;
  background-color: #ffffff;
  border-bottom:solid 1px #eaeaea;
}
.logosection img{
  width:120px;
  margin:10px;
  margin-top: 20px;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 600ms ease-out,
    visibility 1200ms ease-out;
  will-change: opacity, transform, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}
/*
.navbar{
  float: right;
}
.navbar ul {
  list-style-type: none;
  margin-top: 20px;
  padding: 0;
}

.navbar li{
  display: inline;
  padding-right:10px;
  text-transform: uppercase;
  cursor: pointer;
}
.navbar li:hover {
  color:#03a1ff;
}

.navbar li a{
  color: #000000;
  text-decoration: none;
}
.navbar li a:hover{
  color:#03a1ff;
}*/
/*hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh*/

.linkdecoration{
  text-decoration: none;
  color:#ffffff;
}
.linkdecoration :hover{
  text-decoration: none;
  color:#1ab5a2;
}
.nav-link{
  color:#03a1ff;
}

.dropdown-menu{
  background-color: #ffffff;
  border-right:solid #03a1ff 7px;
  padding:20px;
}
.dropdown-menu a{
  border-bottom:solid #ececec 1px;
}
.navbar{
  padding:0px;
  margin-top:40px;
  text-align: right;
  float: right;
 }
 .navbar-nav{
   text-align: right;
 }
.collapse{
  width:100%;
  
}
.navbar ul {
  list-style-type: none;
  padding: 0;
  text-align: right;
  float: right;
}

.navbar li{
  display: inline;
  padding-right:10px;
  text-transform: uppercase;
  cursor: pointer;
  color:red;

}
.navbar li:hover {
  color:#03a1ff;
  
}

.navbar li a{
  color: #131b4d;
  text-decoration: none;
}
.navbar li a:hover{
  color:#03a1ff;
  background-color: #ffffff;
  
}
.navsection{
  height:50px;
  width:100%;
}
.navbar-toggler{
  z-index:100;
  border:0px;
  display: block;
  margin-left: auto;
  margin-right: 0px;
  margin-top:10px;

}
.navbar-toggler i{
  color:#03a1ff;
  font-size: xx-large;
  float: right;
 }
 






.bannersection{
  padding-top:78px;
}
.banner{
  background-image: url("./images/1.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
 background-color: #131b4d;
 min-height: 600px;
 
}
.bannertxt{
  position: relative;
  top:170px;
  color:#ffffff;
  text-align:left; 
}
.bannertxt h1{
  line-height: 1.2em;
}
.bannertxt h4{
  font-weight: 600;
}
.banner-btn{
  padding:10px;
  background-color: #1ab5a2;
  color:#ffffff;
  border-radius: 0px;
  width:190px;
  text-align: center;
  margin-top:20px;
  cursor: pointer;
}
.banner-btn:hover{
  background-color: transparent;
  color:#ffffff;
  border:solid #1ab5a2 1px;
}
.home-btn{
  padding:10px;
  background-color: #1ab5a2;
  color:#ffffff;
  border-radius: 0px;
  width:190px;
  text-align: center;
  margin-top:20px;
  cursor: pointer;
}


.home-btn:hover{
  background-color: transparent;
  color:#f0c429;
  border:solid #1ab5a2 1px;
  
}
.home-btn a{color: #ffffff;}
.home-btn a:hover{color: #1ab5a2; text-decoration: none;}

.sectionheading{
  margin-top:50px;
  margin-bottom:30px;
  text-transform: uppercase;
}
.sectionheading p{
  text-align: center;
  text-transform: none;
  font-size: 1.2em;
  line-height: 1.3em;
  margin-top:20px;
  color: #5f6060;
}
.servicesection{
  padding-bottom:50px;
  padding-top:20px;
}
.servicetxt h5{
  text-align: left;
  margin-top:50px;
  color:#03a1ff;
}
.servicetxt p{
  text-align: left;
}
.whyntcs{
  background-color: #131b4d;
  color:#ffffff;
  padding-bottom:100px;
}
.whyntcs h2{
  margin-top:25px;
}
.whyntcs p{
  color:#ffffff;
}

.whyntcscount{
  border-right:solid #3f4a8b 1px;
}
.lastdiv{
  border-right:0px;
}
.whyntcscount h1{
  font-size:12vh;
  font-weight: bold;
}
.whyntcscount p{
  font-size:3vh;
}
.whyntcscount h1>span{
  font-size:5vh;
  font-weight: bold;
  vertical-align: middle;
}
.whatwedo{
  background-image: url("./images/whatwedo1.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
 background-color: #131b4d;
 min-height: 600px;
 padding-top: 80px;
 padding-bottom: 20px;
 background-attachment: fixed;
 margin-top:78px;
}

.whatedotxt h1{
  text-align: left;
  margin-top:50px;
  color:#ffffff;
}
.whatedotxt p{
  text-align: left;
  color:#ffffff;
}
.whatwedodiv{
  padding:7px;
}
.whatwedoblock{
  background-color: #f0c429;
  height:200px;
  padding:20px;
  transition: 0.6s ease;
  position: relative;
}
.whatwedoblock:hover{
  background-color: #03a1ff;
  height:200px;
  padding:20px;
}
.whatwedoblock i{
  position: absolute;
  font-size: 12vh;
  top:25px;
  right: 25px;
  color:#ffffff;

}
.whatwedoblock p{
  color:#ffffff;
  position: absolute;
  bottom:10px;
  left:20px;
  text-align: left;
  font-size: x-large;
  text-transform: uppercase;
}
.svillage{
  background-image: url("./images/smartvillage.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom;
  background-color: #eaeaea;
}
.eindia{
  background-image: url("./images/eindia.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom;
  background-color: #eaeaea;
}
.eodisha{
  background-image: url("./images/eodisha.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom;
  background-color: #eaeaea;
}
.awards{
  margin:5px;
  height:450px;
}
.awards h6{
  color:#131b4d;
  text-align: left;
  font-weight: bold;
  padding: 15px;
  line-height: 1.5;
  cursor: pointer;
}
.awards h6:hover{
  color:#03a1ff;
}
.awards p{
  color:#5f6060;
  line-height: 1.3;
  text-align: left;
  padding: 15px;
}
.award-btn{
  padding:5px;
  background-color: #03a1ff;
  color:#ffffff;
  width:120px;
  margin-top:20px;
  text-align:center;
  cursor: pointer;
}
.award-btn:hover{
  background-color: #131b4d;
}
.awardsection{
  margin-bottom:50px;
  margin-top:20px;
}




.testimonial{
  background-repeat: no-repeat;
  background-color: #131b4d;
  background-size: 10% 25%;
  background-position: top;
  color:#ffffff;
  padding-bottom:80px;
  margin-bottom:50px;
  padding-top:20px;
}
.testimonial h2{
  margin-top:25px;
}
.testimonial p{
  color:#ffffff;
}
.companyprofile{
  width:350px;
  background-color: #ffffff;
  border-radius: 5px;
  height:90px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  color: #000000;
}
.companylogo{
  float:left;
  background-color:#f0c429;
  width:30%;
  height: 100%;
  border-radius: 5px 0px 0px 5px;  
}
.companylogo img{
  width:90%;
}
.companyname{
  float:right;
  width:70%;
  padding-left:15px;
  padding-top:6%;
  font-size: smaller;
  text-align: left;
  color:#000000;
}
.companyname h5{
  font-weight: 600;
  color:#131b4d;
  font-size: 15px;
}



.carousel-item{
  background-color: transparent;
  min-height:400px;
}
.carousel-caption{
  top:5%;
  position: absolute;
  right: 5%;
  bottom: 20px;
  left: 5%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #000000;
  text-align: center;
}
.arrow >i{
  color: #ffffff;
  position: relative;
  top:-12px;
  font-size: xx-large;
}
.carousel-caption h3{
  color: #03a1ff;
  padding-top:40px;
}
.carousel-caption p{
  color: #ffffff;
}

.carousel-indicators{
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li{
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 13px;
  height: 13px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color:#03a1ff;
  background-clip: padding-box;
  border-top: 0px solid transparent;
  border-bottom: 0px solid transparent;
  opacity: .5;
  transition: 0.6s ease;
  border-radius:50px;
}
.carousel-indicators .active {
  width: 40px;
  background-color: #03a1ff;
  opacity: 1;
}
.carousel-control-prev, .carousel-control-next{
  z-index:16;
}

.team img{
  border-radius: 50%;
  height:170px;
  height:170px;
  margin:20px;
  -webkit-filter: grayscale(100%); 
  filter: grayscale(100%);
}

.teambtn{
  display: block;
  margin-left: auto;
  margin-right: auto;
}





footer{  
  color:#ffffff;
  min-height: max-content;
  background-color: #131b4d;
  padding-bottom:50px;
  
}

.footermap{
  background-image: url("./images/map.svg");
  background-repeat: no-repeat;
  background-color: #131b4d;
  background-size: 80% 80%;
  background-position: center;
  height:400px;
  margin-top:50px;
  border-bottom: solid #3f4a8b 1px;
}

.footermapcontent h4{
  padding-top:50px;
  font-weight: 500;
}
.footermapcontent p{
  line-height:1.3;
  text-align: center;
  margin-top:30px;  
}
.footer-btn{
  margin-top:50px;
  text-transform: uppercase;
  letter-spacing: 0.1em; 
}
footer img{
  width:80%;
}
.socialicon{
  text-align: right;
}
.socialicon ul{
  list-style-type: none;
  padding-right: 0px;
  position: relative;
  padding-top:100px;
  
}
.socialicon li{
  display: inline;
  padding-right:15px;
  cursor: pointer;
}
.footerlogosection{
  margin-top:30px;
  padding-bottom: 20px;
  border-bottom:solid #3f4a8b 1px;
}
.footerlogosection ul{
  list-style-type: none;
  text-align: left;
}
.footerlogosection li a{
  color:#ffffff;
  text-decoration: none;
  transition: 0.5s;
}
.footerlogosection li a:hover{
  color:#03a1ff;
  text-decoration: none;
  padding-left:15px;
}
.footerlogosection p{
  font-size:12px;
  padding-top:10px;
}
.footerlogosection img{ 
  padding-bottom: 50px;
}
.footerbottom{
  padding-top:20px;
  text-align: center;
}
.xyz{
  padding-top:150px;
  padding-bottom:200px;
}


/*--------------------------------SERVICE PAGE START---------------------*/
.servicepage{
  padding-top:78px;
}
.serviceheader{
  background-image: url("./images/ERPheader.jpg");
  background-repeat: no-repeat;
  background-size: 100% 60%;
 min-height: 250px;
 padding-top: 20%;
 background-attachment: fixed;
}
.trainingheader{
  background-image: url("./images/trainingbanner.jpg");
  background-repeat: no-repeat;
  background-size: 100% 60%;
 min-height: 250px;
 padding-top: 20%;
 background-attachment: fixed;
}
.AIMLheader{
  background-image: url("./images/HeaderAIML.jpg");
  background-repeat: no-repeat;
  background-size: 100% 60%;
 min-height: 250px;
 padding-top: 20%;
 background-attachment: fixed;
}
.IoTheader{
  background-image: url("./images/iotheader.jpg");
  background-repeat: no-repeat;
  background-size: 100% 60%;
 min-height: 250px;
 padding-top: 20%;
 background-attachment: fixed;
}
.appdevbanner{
  background-image: url("./images/appdevbanner.jpg");
  background-repeat: no-repeat;
  background-size: 100% 60%;
 min-height: 250px;
 padding-top: 20%;
 background-attachment: fixed;
}
.supermarketbanner{
  background-image: url("./images/supermarket.jpg");
  background-repeat: no-repeat;
  background-size: 100% 60%;
 min-height: 250px;
 padding-top: 20%;
 background-attachment: fixed;
}
.medEzybanner{
  background-image: url("./images/medEZYbanner.jpg");
  background-repeat: no-repeat;
  background-size: 100% 60%;
 min-height: 250px;
 padding-top: 20%;
 background-attachment: fixed;
}
.serviceheader h1{
  position: relative;
  top:50px;
  text-transform: uppercase;
  color:#ffffff;
}

.pageheader{
  padding-top:50px;
  padding-bottom:50px;  
}
.pageheader h2{
  color:#131b4d;
  font-weight:600;  
}
.productblock{
  padding:5px;
  position: relative;
  height: 320px;
}
.productimage{
  border-radius: 50%;
  margin: 5px;
  border:solid #ffffff 5PX;
  height: 300px;
  width: 300px;
  position: absolute;
  left:0px;
  z-index: 2;
  box-shadow: 0 5px 0px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.products{
  background-color: #03a1ff;
  color:#ffffff;
  height: 200px;
  border-radius: 5px;
  position: absolute;
  left:150px;
  top: 50px;
  padding: 20px;
  padding-left: 170px;
  text-align: left;
  font-size:18px;
  line-height: 1.3;
 
}
.products h5 a{
  position: absolute;
  bottom:20px;
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
}
.products h5 a:hover{
  color: #131b4d;
}
.products-R h5 a{
  position: absolute;
  bottom:20px;
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
  left: 20px;
}
.products-R h5 a:hover{
  color: #03a1ff;
}

.productimage-R{
  border-radius: 50%;
  border:solid #ffffff 5PX;
  height: 300px;
  width: 300px;
  position: absolute;
  right:0px;
  z-index: 2;
  box-shadow: 0 5px 0px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.products-R{
  background-color: #131b4d;
  color:#ffffff;
  height: 200px;
  border-radius: 5px;
  position: absolute;
  right:150px;
  top: 50px;
  padding: 20px;
  padding-right: 170px;
  text-align: right;
  font-size:18px;
  line-height: 1.3;
}
.productrow{
  margin-top: 40px;
}

.innersection{
  margin-top: 50px;
}
.innerheader h5{
  text-align: left;
   color:#03a1ff;
}
.innerheader p{
  text-align: left;
}
.ERPsection{
  height:500px;
  margin-top:50px;
}
.blankdiv{
  height:40px;
}
.ERP img{
   border-radius: 50%;
  border:solid #f0c429 1PX;
  padding:10px;
}

.educationerp-row{
  position: relative;
  right:-60px;
  text-align: right;
}
.educationerp-row1{
  text-align: right;
}
.educationerp-row-R{
  position: relative;
  left:-80px;
  text-align: right;
}
.educationerp-row1-R{
  text-align: left;
  position: relative;
  left:-25px;
}
.educationerp{
  padding: 0px;
  text-align: right;
}
.educationerp-R{
  padding: 0px;
  text-align: left;
}

.educationerp p{
  color:#f0c429;
  font-weight: 600;
}
.educationerp-R p{
  color:#f0c429;
  font-weight: 600;
  padding-left:25px;
}
.educationerp ul{
  text-align: right;
  margin-top:-15px;
}
.educationerp li{
  text-align: right;
  list-style-type: none;
}
.educationerp-R ul{
  text-align: left;
  margin-top:-15px;
  
}
.educationerp-R li{
  text-align: left;
  list-style-type: none;
  margin-left:-15px;
}

.educationerp-icon{
  background-color: #ffffff;
  border:solid #f0c429 1px;
  color:#ffffff;
  height: 90px;
  width:90px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.educationerp-icon i{
  background-color: #f0c429;
  color:#ffffff;
  height: 80px;
  width:80px;
  border-radius: 50%;
  padding: 23px;
  font-size: xx-large;
}

.educationerp-icon-R{
  background-color: #ffffff;
  border:solid #f0c429 1px;
  color:#ffffff;
  height: 90px;
  width:90px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.educationerp-icon-R i{
  background-color: #f0c429;
  color:#ffffff;
  height: 80px;
  width:80px;
  border-radius: 50%;
  padding: 23px;
  font-size: xx-large;  
}

.m-b{
  margin-bottom:50px;
}
.mobileAppsection{
  margin-top:30px;
  margin-bottom:50px;
}
.mobileApp{
  display: flex;
  align-items: center;
  justify-content: center;

}
.mobileApp img{
  padding:10px;
  width:75%;  
}
.mobileAppleft{
  margin-top:40px;

}
.mobileAppright{
  margin-top:40px;

}
.mobileApprow{
  text-align: right;
  position: relative;
  right:0px;
}
.mobileApprow-R{
  text-align: left;
  position: relative;
  left:-25px;
}

/*--------------------------------SERVICE PAGE END---------------------*/


/*--------------------------------COMAPNY PAGE START---------------------*/
.companytxt p{
  text-align: justify;
}

.mentors img{
  border:Solid #d3d3d3 1px;
  border-radius: 5%;
}
.mentors p{
  font-size: medium;
  font-weight: 600;
  padding-top: 10px;
}
.mentors span{
  font-weight: 500;
  position: relative;
  top:-10px;
}
.mntors-section{
  margin-bottom: 50px;
}
/*--------------------------------COMAPNY PAGE END---------------------*/


/*--------------------------------TEAM PAGE START---------------------*/
.teamheader{
  background-image: url("./images/teamheader1.jpg");
  background-repeat: no-repeat;
  background-size: 100% 60%;
 min-height: 250px;
 padding-top: 20%;
 background-attachment: fixed;
}
.teampic{
  margin-bottom:50px;
}
.teampic img{
  border-radius: 50%;
  height:170px;
  height:170px;
  -webkit-filter: grayscale(100%); 
  filter: grayscale(100%);
}
.teampic i{
  background-color: #03a1ff;
  color: #ffffff;
  height:25px;
  width:25px;
  font-weight: 400;
  font-size: smaller;
  border-radius: 50%;
  padding:5px;
  position: absolute;
  bottom:70px;
  right:30px;
  cursor: pointer;
  z-index: 1;
}
.teampic i:hover{
  background-color: #f0c429;
  color:#000000;
}
.teampic h3{
  font-weight: 600;
  color: #131b4d;
  font-size: large;
  margin-top: 10px;
}
.teambottomsection{
  background-color: #eaeaea;
  color:#5f6060;
  padding: 40px;
}
.photosection{
  margin-top: 50px;
  margin-bottom: 50px;
}
.grouppicsection{
  margin-bottom: 20px;
}
.grouppicsection img{
  border:solid #d3d3d3 1px;
}
.grouppicsection p{
  padding-top: 10px;
  font-weight: 500;
}


.imgcircle {
  position: relative;
  padding: 0;
  cursor: pointer;
  overflow: hidden;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.content {
  width:170px;
  height:170px;
  opacity: 0;
  font-size: 20px;
  position: absolute;
  top: 0;
  left: 32px;
  color: #ffffff;
  background-color: rgba(19, 27, 77, 0.9);
  border-radius: 50%;
  -webkit-transition: all 400ms ease-out;
  -moz-transition: all 400ms ease-out;
  -o-transition: all 400ms ease-out;
  -ms-transition: all 400ms ease-out;
  transition: all 400ms ease-out;
  text-align: center;
  
}
.imgcircle .content:hover {
  opacity: 1;
}
.imgcircle .content .text {
  height: 0;
  opacity: 1;
  transition-delay: 0s;
  transition-duration: 0.4s;
}
.imgcircle .content:hover .text {
  opacity: 1;
  transform: translateY(90px);
  -webkit-transform: translateY(90px);
}
.text{
  margin-top:-20px;
}

/*--------------------------------TEAM PAGE END---------------------*/

/*--------------------------------WHAT WE DO PAGE START---------------------*/
.paddingbottom{
  margin-bottom: 50px;
}
.wedosection{
  margin-top:30px;
}
.wedosection h3{
  text-align: left;
  color:#03a1ff;
  font-size: x-large;
  font-weight: 600;
  padding-top: 5px;
}
.wedosection p{
  text-align: justify;
}
.wedosection img{
  padding-top:50px;
}
/*--------------------------------WHAT WE DO PAGE END---------------------*/
/*--------------------------------CONTACT PAGE START---------------------*/
.contactcetogoryrow{
  padding-top:10px;
}
.contactcetogory{
  background-color:#131b4d ;
  color:#ffffff;
  padding:20px;
  text-align: left;
}
.catogory1{
  background-color:#005e96 ;
}
.catogory2{
  background-color:#03a1ff;
}
.contactcetogory h5{
  font-weight: 600;  
}
.contactcetogory p{
  padding-top:50px;
  font-weight: 600;  
}
.contactcetogory span{
  position: relative;
  top:-15px; 
}

.addressimg{
  background-image: url("./images/addressimg.jpg");
  background-repeat: no-repeat;
  background-color: #131b4d;
  background-size: 100% 100%;
  background-position: center;
}
.addressblock{
  margin-bottom: 50px;
}
.address{
  text-align: right;
  margin:30px;
  background-color: rgba(0, 0, 0, 0.7);
  color:#ffffff;
  padding:20px;
  width:400px;
  float: right;
  border-right:solid 10px #f0c429 ;
}
.address h6{
  color:#f0c429;
  font-weight: 600;
}
.address p{
  position: relative;
  top:-10px;
}
/*--------------------------------CONTACT PAGE END---------------------*/

/*--------------------------------Supermarket PAGE END---------------------*/
.providediv{
  background-color: #eaeaea;
  padding: 20px;
  height:310px;
  margin-top: 10px;
  border-radius: 5px;
}
.providediv img{
  width:100px;
  padding-top:20px;
  padding-bottom:20px;
}
.providediv h4{
  color:#03a1ff;
  font-size: x-large;
  font-weight: 600;
  padding-top: 5px;  
}
.brandinglogo{
  width:300px;
}
.icondiv{
  display: flex;
  justify-content: center;
}
.icondiv h5{
  margin-top: 20px;
}
.servicediv{
  background-color: #fb243e;
  background-image: linear-gradient(#fc5a73, #fb243e);
  border-radius: 23%; 
   height:200px;
   width:200px;
   display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 25px 1px 20px #cacaca; 
  cursor: pointer;
}

.servicediv:hover #hoverShow {
  display: block;  
 } 
 .servicediv #hoverShow {
  display: none;
  position: absolute;
  background-color: #fb243e;
  background-image: linear-gradient(#fc5a73, #fb243e);
  width: 300px;
  height: 300px;
  left: -50px;
  top: -50px;
  z-index: 0;
  border-radius: 10%;
  padding: 10px;
  color:#ffffff;
   box-shadow: 25px 1px 20px #cacaca;
   text-align: left;
 }
 .opendiv img{
   width:50px;
   text-align: center;
 }
 .opendiv > span{
  font-weight:bold;
  padding-left:5px;
 }
 .opendiv ul{
   text-align: left;
 }
 .opendiv li{
  margin-left:-15px;
}
 .opendiv hr{
   border-color:#ffffff;
 }


.servicediv1{
  background-color: #121212;
  background-image: linear-gradient(#323232, #121212);
  border-radius: 23%; 
   height:200px;
   width:200px;
   display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 25px 1px 20px #cacaca;
  cursor: pointer; 
}

.servicediv1:hover #hoverShow1 {
  display: block;  
 } 
 .servicediv1 #hoverShow1 {
  display: none;
  position: absolute;
  background-color: #121212;
  background-image: linear-gradient(#323232, #121212);
  width: 300px;
  height: 300px;
  left: -50px;
  top: -50px;
  z-index: 0;
  border-radius: 10%;
  padding: 10px;
  color:#ffffff;
   box-shadow: 25px 1px 20px #cacaca;
   text-align: left;
 }

.servicediv2{
  background-color: #02a28e;
  background-image: linear-gradient(#1ab5a2, #02a28e);
  border-radius: 23%; 
   height:200px;
   width:200px;
   display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 25px 1px 20px #cacaca;
  cursor: pointer;

}

.servicediv2:hover #hoverShow2 {
  display: block; 

 } 
 .servicediv2 #hoverShow2 {
  display: none;
  position: absolute;
  background-color: #02a28e;
  background-image: linear-gradient(#1ab5a2, #02a28e);
  width: 300px;
  height: 300px;
  left: -50px;
  top: -50px;
  z-index: 0;
  border-radius: 10%;
  padding: 10px;
  color:#ffffff;
   box-shadow: 25px 1px 20px #cacaca;
   text-align: left;

 }
.servicediv3{
  background-color: #62b352;
  background-image: linear-gradient(#78c26a, #62b352);
  border-radius: 23%; 
   height:200px;
   width:200px;
   display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 25px 1px 20px #cacaca;
  cursor: pointer; 
}

.servicediv3:hover #hoverShow3 {
  display: block; 

 } 
 .servicediv3 #hoverShow3 {
  display: none;
  position: absolute;
  background-color: #62b352;
  background-image: linear-gradient(#78c26a, #62b352);
  width: 300px;
  height: 300px;
  left: -50px;
  top: -50px;
  z-index: 0;
  border-radius: 10%;
  padding: 10px;
  color:#ffffff;
   box-shadow: 25px 1px 20px #cacaca;
   text-align: left;
 }

.servicediv4{
  background-color: #dbb01a;
  background-image: linear-gradient(#f0c429, #dbb01a);
  border-radius: 23%; 
   height:200px;
   width:200px;
   display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 25px 1px 20px #cacaca;
  cursor: pointer; 
}
.servicediv4:hover #hoverShow4 {
  display: block; 

 } 
 .servicediv4 #hoverShow4 {
  display: none;
  position: absolute;
  background-color: #dbb01a;
  background-image: linear-gradient(#f0c429, #dbb01a);
  width: 300px;
  height: 300px;
  left: -50px;
  top: -50px;
  z-index: 0;
  border-radius: 10%;
  padding: 10px;
  color:#ffffff;
   box-shadow: 25px 1px 20px #cacaca;
   text-align: left;
 }

 .servicediv5{
  background-color: #ff7c06;
  background-image: linear-gradient(#fe892a, #ff7c06);
  border-radius: 23%; 
   height:200px;
   width:200px;
   display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 25px 1px 20px #cacaca;
  cursor: pointer; 
}
.servicediv5:hover #hoverShow5 {
  display: block; 

 } 
 .servicediv5 #hoverShow5 {
  display: none;
  position: absolute;
  background-color: #ff7c06;
  background-image: linear-gradient(#fe892a, #ff7c06);
  width: 300px;
  height: 300px;
  left: -50px;
  top: -50px;
  z-index: 0;
  border-radius: 10%;
  padding: 10px;
  color:#ffffff;
   box-shadow: 25px 1px 20px #cacaca;
   text-align: left;
 }
.icon-mg{
  height:130px;
  width:130px;
}

/*--------------------------------Supermarket PAGE END---------------------*/

.ailm-p{
  padding-top:50px;
}
@media (min-width: 350px){
  .banner{
    min-height: 430px;
   }
   .bannertxt{
     position: relative;
     top:20px;
   }
   .bannertxt h1{
      font-size: 35px;
      font-weight: 550;
   }
   .bannertxt h4{
    font-size: 20px;
 }
 .bannertxt a {
   text-decoration: none;
   color: #ffffff;
 }

 
   .whyntcscount{
    border-bottom:solid #3f4a8b 1px;
    border-right:0px;
  }
  .lastdiv{
    border-right:0px;
  }
  .whyntcscount h1{
    font-size:20vh;
    font-weight: bold;
  }
  .whyntcscount p{
    font-size:5vh;
  }
  .whyntcscount h1>span{
    font-size:10vh;
    font-weight: bold;
    vertical-align: middle;
  }
  .awards{
    margin:5px;
    height:450px;
  }
  .productblock{
    height: 200px;
  }
  .productimage{
    border:solid #ffffff 5PX;
    height: 160px;
    width: 160px;
    left:0px;
  }
  .products{
    height: 140px;
    left:75px;
    top: 18px;
    padding: 20px;
    padding-left: 100px;
    text-align: left;
    font-size:15px;
    line-height: 1.2;
  }
  .products h5 a {
    font-size:18px;
  }
  .products-R h5 a {
    font-size:18px;
  }
  .productimage-R{
    border:solid #ffffff 5PX;
    height: 160px;
    width: 160px;
    right:0px;
  }
  .products-R{
    height: 140px;
    right:75px;
    top: 18px;
    padding: 20px;
    padding-right: 100px;
    text-align: right;
    font-size:15px;
    line-height: 1.2;
  }

  .Ctrainning img{
    margin-top:50px;
  }
  .Strainning img{
    margin-bottom:50px;
  }

  .footermap{
    height:450px;
  }
  
  .footermapcontent p{
    text-align: justify;
  }
  .socialicon ul{
    top:-100px;
  }
   .socialicon a{
    text-decoration: none;
    color:#ffffff;
  }
  .socialicon a:hover{
    text-decoration: none;
    color:#f0c429;
  }
  .ERPsection{
    height:auto;
  }
  .educationerp-row{
    position: relative;
    right:20px;
    text-align: right;
  }
  .educationerp-row1{
    position: relative;
    right:20px;
    text-align: right;
  }
  .educationerp-row-R{
    position: relative;
    left:0px;
    text-align: right;
  }
  .educationerp-row1-R{
    text-align: left;
    position: relative;
    left:0px;
  }
  .ERP img{
    border-radius: 50%;
   border:solid #f0c429 1PX;
   padding:10px;
   margin-top:0px;
 }
 .mobileApprow{
  text-align: right;
  position: relative;
  right:20px;
}
.mobileApprow-R{
  text-align: left;
  position: relative;
  left:0px;
}
.teampic i{
  position: absolute;
  bottom:50px;
  right:25px;
  cursor: pointer;
  z-index: 1;
}

}
@media (min-width: 576px) {
  .banner{
   min-height: 400px;
  }
  .bannertxt{
    position: relative;
    top:20px;
  }
  .whyntcscount{
    border-bottom:solid #3f4a8b 1px;
    border-right:0px;
  }
  .lastdiv{
    border-right:0px;
  }
  .whyntcscount h1{
    font-size:20vh;
    font-weight: bold;
  }
  .whyntcscount p{
    font-size:5vh;
  }
  .whyntcscount h1>span{
    font-size:10vh;
    font-weight: bold;
    vertical-align: middle;
  }
  .awards{
    margin:5px;
    height:550px;
  }
  .productblock{
    height: 250px;
  }
  .productimage{
    border:solid #ffffff 5PX;
    height: 220px;
    width: 220px;
    left:0px;
  }
  .products{
    height: 170px;
    left:100px;
    top: 25px;
    padding: 20px;
    padding-left: 150px;
    text-align: left;
    font-size:15px;
    line-height: 1.2;
  }
  .products h5 a {
    font-size:18px;
  }
  .products-R h5 a {
    font-size:18px;
  }
  .productimage-R{
    border:solid #ffffff 5PX;
    height: 220px;
    width: 220px;
    right:0px;
  }
  .products-R{
    height: 170px;
    right:100px;
    top: 25px;
    padding: 20px;
    padding-right: 150px;
    text-align: right;
    font-size:15px;
    line-height: 1.2;
  }
  .Ctrainning img{
    margin-top:50px;
  }
  .Strainning img{
    margin-bottom:50px;
  }
  .footermap{
    height:380px;
  }  
  .footermapcontent p{
    text-align: justify;
  }
  .socialicon ul{
    top:-100px;
  }
  
  .ERPsection{
    height:auto;
  }
  .educationerp-row{
    position: relative;
    right:10px;
    text-align: right;
  }
  .educationerp-row1{
    position: relative;
    right:10px;
    text-align: right;
  }
  .educationerp-row-R{
    position: relative;
    left:0px;
    text-align: right;
  }
  .educationerp-row1-R{
    text-align: left;
    position: relative;
    left:0px;
  }
  .ERP img{
    border-radius: 50%;
   border:solid #f0c429 1PX;
   padding:10px;
   margin-top:0px;
 }
 .mobileApprow{
  text-align: right;
  position: relative;
  right:20px;
}
.mobileApprow-R{
  text-align: left;
  position: relative;
  left:0px;
}
.teampic i{
  position: absolute;
  bottom:70px;
  right:50px;
  cursor: pointer;
  z-index: 1;
}

}

@media (min-width: 768px) {
  .banner{
    min-height: 400px;
   }
   .bannertxt{
     position: relative;
     top:20px;
   }

   .whyntcscount{
    border-bottom:solid #3f4a8b 1px;
    border-right:0px;
  }
  .lastdiv{
    border-right:0px;
  }
  .whyntcscount h1{
    font-size:20vh;
    font-weight: bold;
  }
  .whyntcscount p{
    font-size:5vh;
  }
  .whyntcscount h1>span{
    font-size:10vh;
    font-weight: bold;
    vertical-align: middle;
  }
  .awards{
    margin:5px;
    height:650px;
  }

  .productblock{
    height: 300px;
  }
  .productimage{
    border:solid #ffffff 5PX;
    height: 260px;
    width: 260px;
    left:0px;
  }
  .products{
    height: 200px;
    left:150px;
    top: 40px;
    padding: 20px;
    padding-left: 150px;
    text-align: left;
    font-size:15px;
    line-height: 1.2;
  }
  .products h5 a {
    font-size:18px;
  }
  .products-R h5 a {
    font-size:18px;
  }
  .productimage-R{
    border:solid #ffffff 5PX;
    height: 260px;
    width: 260px;
    right:0px;
  }
  .products-R{
    height: 200px;
    right:150px;
    top: 40px;
    padding: 20px;
    padding-right: 150px;
    text-align: right;
    font-size:15px;
    line-height: 1.2;
  }
  .Ctrainning img{
    margin-top:50px;
  }
  .Strainning img{
    margin-bottom:50px;
  }
  .footermap{
    height:380px;
  }  
  .footermapcontent p{
    text-align: justify;
  }
  .socialicon ul{
    top:-50px;
  }
  .ERPsection{
    height:auto;
  }
  .educationerp-row{
    position: relative;
    right:10px;
    text-align: right;
  }
  .educationerp-row1{
    position: relative;
    right:10px;
    text-align: right;
  }
  .educationerp-row-R{
    position: relative;
    left:0px;
    text-align: right;
  }
  .educationerp-row1-R{
    text-align: left;
    position: relative;
    left:0px;
  }
  .ERP img{
    border-radius: 50%;
   border:solid #f0c429 1PX;
   padding:10px;
   margin-top:0px;
 }
 .mobileApprow{
  text-align: right;
  position: relative;
  right:20px;
}
.mobileApprow-R{
  text-align: left;
  position: relative;
  left:0px;
}
.teampic i{
  position: absolute;
  bottom:70px;
  right:30px;
  cursor: pointer;
  z-index: 1;
}
}

@media (min-width: 992px) {
  .banner{
    min-height: 600px;
   }
   .bannertxt{
     position: relative;
     top:170px;
   }
   .whyntcscount{
    border-right:solid #3f4a8b 1px;
    border-bottom:0px;
  }
  .lastdiv{
    border-right:0px;
  }
  .whyntcscount h1{
    font-size:12vh;
    font-weight: bold;
  }
  .whyntcscount p{
    font-size:3vh;
  }
  .whyntcscount h1>span{
    font-size:5vh;
    font-weight: bold;
    vertical-align: middle;
  }
  .awards{
    margin:5px;
    height:430px;
  }
  .productblock{
    height: 250px;
  }
  .productimage{
    border:solid #ffffff 5PX;
    height: 230px;
    width: 230px;
    left:0px;
  }
  .products{
    height: 180px;
    left:80px;
    top: 30px;
    padding: 20px;
    padding-left: 170px;
    text-align: left;
    font-size:16px;
    line-height: 1.3;
  }
  .products h5 a {
    font-size:19px;
  }
  .products-R h5 a {
    font-size:19px;
  }
  .productimage-R{
    border:solid #ffffff 5PX;
    height: 230px;
    width: 230px;
    right:0px;
  }
  .products-R{
    height: 180px;
    right:80px;
    top: 30px;
    padding: 20px;
    padding-right: 170px;
    text-align: right;
    font-size:16px;
    line-height: 1.3;    
  }
  .Ctrainning img{
    margin-top:0px;
  }
  .Strainning img{
    margin-bottom:0px;
  }
  .footermap{
    height:380px;
  }  
  .footermapcontent p{
    text-align: center;
  }
  .socialicon ul{
    top:0px;
  }
  .ERPsection{
    height:auto;
  }
  .educationerp-row{
    position: relative;
    right:-40px;
    text-align: right;
  }
  .educationerp-row1{
    position: relative;
    right:20px;
    text-align: right;
  }
  .educationerp-row-R{
    position: relative;
    left:-80px;
    text-align: right;
  }
  .educationerp-row1-R{
    text-align: left;
    position: relative;
    left:-25px;
  }
  .ERP img{
    border-radius: 50%;
   border:solid #f0c429 1PX;
   padding:10px;
   margin-top:50px;
 }
 .mobileApprow{
  text-align: right;
  position: relative;
  right:0px;
}
.mobileApprow-R{
  text-align: left;
  position: relative;
  left:-25px;
}
.teampic i{
  position: absolute;
  bottom:70px;
  right:15px;
  cursor: pointer;
  z-index: 1;
}

}

@media (min-width: 1200px) {
  .banner{
    min-height: 650px;
   }
   .bannertxt{
     position: relative;
     top:170px;
   }
   .whyntcscount{
    border-right:solid #3f4a8b 1px;
    border-bottom:0px;
  }
  .lastdiv{
    border-right:0px;
  }
  .whyntcscount h1{
    font-size:12vh;
    font-weight: bold;
  }
  .whyntcscount p{
    font-size:3vh;
  }
  .whyntcscount h1>span{
    font-size:5vh;
    font-weight: bold;
    vertical-align: middle;
  }
  .awards{
    margin:5px;
    height:450px;
  }
  .productblock{
    height: 320px;
  }
  .productimage{
    border:solid #ffffff 5PX;
    height: 300px;
    width: 300px;
    left:0px;
  }
  .products{
    height: 200px;
    left:150px;
    top: 50px;
    padding: 20px;
    padding-left: 170px;
    text-align: left;
    font-size:18px;
    line-height: 1.5;
  }
  .products h5 a {
    font-size:20px;
  }
  .products-R h5 a {
    font-size:20px;
  }
  .productimage-R{
    border:solid #ffffff 5PX;
    height: 300px;
    width: 300px;
    right:0px;
  }
  .products-R{
    height: 200px;
    right:150px;
    top: 50px;
    padding: 20px;
    padding-right: 170px;
    text-align: right;
    font-size:18px;
    line-height: 1.5;
  }
  .Ctrainning img{
    margin-top:0px;
  }
  .Strainning img{
    margin-bottom:0px;
  }
  .footermap{
    height:400px;
  }  
  .footermapcontent p{
    text-align: center;
  }
  .socialicon ul{
    top:0px;
  }
  .ERPsection{
    height:auto;
  }
  .educationerp-row{
    position: relative;
    right:-60px;
    text-align: right;
  }
  .educationerp-row1{
    text-align: right;
  }
  .educationerp-row-R{
    position: relative;
    left:-80px;
    text-align: right;
  }
  .educationerp-row1-R{
    text-align: left;
    position: relative;
    left:-25px;
  }
  .ERP img{
    border-radius: 50%;
   border:solid #f0c429 1PX;
   padding:10px;
   margin-top:0px;
 }
 .mobileApprow{
  text-align: right;
  position: relative;
  right:0px;
}
.mobileApprow-R{
  text-align: left;
  position: relative;
  left:-25px;
}
.teampic i{
  position: absolute;
  bottom:70px;
  right:30px;
  cursor: pointer;
  z-index: 1;
}

}




